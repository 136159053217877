import React, { useEffect, useState } from 'react';
import './modal.css';

const Modal = ({ onAccept, onReject }) => {
  const contentPairs = [
    { title: "AJÁ Y TÚ QUE?", message: "Aquí no entran pelad@s. Solo mayores, ¿Tienes 18 años o más?" },
    { title: "¡AVE MARÍA PUES!", message: "Aquí no entran culicagados. Contáme, ¿Ya tenes 18 años o más?" },
    { title: "¡MIRÁ VE!", message: "Aquí no entran chinches. ¿Ya tienes 18 años o más?" },
    { title: "¡BIEN O QUÉ!", message: "Aquí no entran patoj@s. ¿Eres mayor de 18 años?" }
  ];

  const [randomContent, setRandomContent] = useState({ title: "", message: "" });

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * contentPairs.length);
    setRandomContent(contentPairs[randomIndex]);

    document.body.classList.add('blur-background');

    return () => {
      document.body.classList.remove('blur-background');
    };
  }, []);

  return (
    <div id="ageModal" className="modal">
      <div className="modal-content">
        <h2>{randomContent.title}</h2>
        <p>{randomContent.message}</p>
        <button onClick={onAccept}>Sí</button>
        <button onClick={onReject}>No</button>
      </div>
    </div>
  );
};

export default Modal;
