import React, { useState, useEffect } from 'react';
import './App.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Modal from './components/modal';
import IframeModal from './components/IframeModal'; // Importa el nuevo componente del modal
import Logo from './assets/logo.svg'; // Importar tu SVG de logo aquí

const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const [isIframeModalOpen, setIsIframeModalOpen] = useState(false);

  const handleAccept = () => {
    setIsModalOpen(false);
  };

  const handleReject = () => {
    window.location.href = 'https://www.google.com';
  };

  const handleRegister = () => {
    setIsIframeModalOpen(true);
  };

  const handleCloseIframeModal = () => {
    setIsIframeModalOpen(false);
  };

  useEffect(() => {
    const content = document.getElementById('content');
    if (isModalOpen) {
      content.classList.add('blur-background');
    } else {
      content.classList.remove('blur-background');
    }
  }, [isModalOpen]);

  return (
    <div className="App">
      <div className="overlay"></div>
      {isModalOpen && <Modal onAccept={handleAccept} onReject={handleReject} />}
      <div id="content">
        <main>
          <div className="logo">
            <img src={Logo} alt="Logo" />
          </div>
          <h1 className='titel'>Cada sorbo es un saludo a nuestra tierra</h1> <br />
          <p className="message">
            Estamos emocionados de presentarte un mundo de cerveza artesanal y buenos momentos. <br />
            ¡Registrate y sorprendete!
          </p>
          <button className="register-button" onClick={handleRegister}>
            Regístrate
          </button>
        </main>
        <footer>
          <div className="social-icons">
            <a href="https://www.facebook.com/people/Numati-Cervecer%C3%ADa-Colombiana/61555194496846/" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-facebook-f"></i>
            </a>
            <a href="https://x.com/NumatiCerv" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-x-twitter"></i>
            </a>
            <a href="https://www.instagram.com/numaticerveceriacolombiana/" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </footer>
      </div>
      <IframeModal
        isOpen={isIframeModalOpen}
        onClose={handleCloseIframeModal}
      />
    </div>
  );
};

export default App;
