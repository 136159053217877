import React from 'react';
import './IframeModal.css'; // Asegúrate de tener un archivo CSS para estilos del modal

const IframeModal = ({ isOpen, onClose, iframeSrc }) => {
  if (!isOpen) return null;

  return (
    <div className="iframe-modal">
      <div className="iframe-modal-content">
        <button className="iframe-modal-close" onClick={onClose}>
          &times;
        </button>
        <iframe
            src="https://docs.google.com/forms/d/e/1FAIpQLScztAB1-fjok_P3fquBpQ3rs7ii4q6hq0oLtR5NEy5-ImwEHQ/viewform?embedded=true"
            width="640"
            height="833"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
        >
            Cargando…
        </iframe>
      </div>
    </div>
  );
};

export default IframeModal;
